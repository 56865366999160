@import 'styles/variables';

.events {
	padding-top: 0;
	display: flex;
	flex-direction: column;

	&__title {
		padding-bottom: 8px;
		font-family: $font-roboto-bold;
		font-size: 24px;
		letter-spacing: 0.96px;
		background: white;
		color: $color-black-dark;
		position: sticky;
		top: 0;
	}

	&__btn {
		// width: 260px;
		margin: 0 18px 16px 0;
		display: flex;
		justify-content: flex-end;
		background: white;
		position: sticky;
		top: 33px;
		z-index: 10;

		&_link {
			padding: 12px 52px;
			display: inline-block;
			font-family: $font-roboto-bold;
			font-size: 18px;
			color: $color-white;
			border-radius: 16px;
			background: $color-blue-btn;
			box-shadow: 4px 4px 6px 0 rgba(60, 61, 64, 0.25);
		}
	}

	&__list {
		padding-top: 40px;
		display: flex;
		flex-direction: column;
		gap: 23px;
	}

	&__item {
			
		&_title {
			font-family: $font-roboto-bold;
			font-size: 20px;
			letter-spacing: 0.6px;
			color: $color-black-dark;
		}

		&_text {
			font-family: $font-mulish-light;
			font-size: 16px;
			color: $color-black-dark;

			&_red {
				color: $color-red;
			}
		}
	}
}

.event {

	&_back {
		margin-bottom: 12px;
		display: flex;
		align-items: center;
		font-family: $font-mulish-bold;
		font-size: 14px;
		color: #000;

		&::before {
			content: url('../../assets/svg/ArrowToBack.svg');
			width: 24px;
			height: 24px;
			margin-right: 8px;
		}
	}

	&__title {
		margin-bottom: 75px;
		font-family: $font-roboto-bold;
		font-size: 24px;
		letter-spacing: 0.96px;
		color: $color-black-dark;
	}

	&__buttonblock {
		display: flex;
		align-items: center;
		gap: 32px;
		position: fixed;
		bottom: 24px;
		padding-bottom: 24px;
		padding-top: 12px;
		width: 100%;
		background: white;
	}

	&__protocolBlock {
		width: 100%;
		padding: 10px;
		border-radius: 16px;
		background: $color-grey-blue;

		&__heading{
			color: $color-blue-btn;
			font-family: $font-mulish-light;
			font-size: 16px;
			line-height: 20px;
			padding-bottom: 16px;
		}

		&__item {
			padding: 10px 0;
		}

		&__itemName {
			font-family: $font-mulish-bold;
			font-size: 20px;
			line-height: 23px;
			letter-spacing: 0.03em;
		}

		&__itemDiscription {
			font-family: $font-mulish-light;
			font-size: 16px;
			line-height: 23px;
			color: $color-grey-dark;
			padding: 4px 0;
		}
	}


	&__form {
		display: flex;
		flex-direction: column;
		gap: 60px;

		&_btn {
			margin-bottom: 40px;

			&_link {
				width: 260px;
				padding: 20px 60px;
				display: inline-block;
				font-family: $font-roboto-bold;
				font-size: 18px;
				color: $color-white;
				border-radius: 16px;
				background: $color-blue-btn;
				box-shadow: 4px 4px 6px 0 rgba(60, 61, 64, 0.25);
			}
		}
	}
}


.eventItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	border-radius: 12px;
	padding: 5px;
	border: 2px solid transparent;


	&__title {
		font-family: $font-roboto-bold;
		font-size: 20px;
		letter-spacing: 0.6px;
		color: $color-black-dark;
	}

	&__text {
		font-family: $font-mulish-light;
		font-size: 16px;
		line-height: 20px;
		color: $color-grey-dark;

		&_red {
			color: $color-red;
		}
	}

	&__buttonWrapper {
		display: flex;
		gap: 10px;
	}

	//&__deleteButton {
	//	width: 50px;
	//	height: 50px;
	//	visibility: visible;
	//	display: flex;
	//	flex-direction: column;
	//	align-items: center;
	//	justify-content: center;
	//	border: $color-red solid 1px;
	//	border-radius: 16px;
	//	background: none;
	//
	//	& > svg {
	//		fill: $color-red;
	//		width: 24px;
	//		height: 24px;
	//	}
	//
	//	&:hover {
	//		background: $color-red;
	//		> svg {
	//			fill: white;
	//		}
	//	}
	//
	//	&:active {
	//		background: $color-dark-red;
	//		> svg {
	//			fill: white;
	//		}
	//	}
	//}

}

.eventItem:hover {
	//border: $color-grey-mid solid 2px;

	.eventItem {

		&__title {
			color: $color-blue-btn;
		};

		&__deleteButton {
			visibility: visible;
		};
	}
}
