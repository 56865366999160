@import 'styles/variables';

.header {
	padding: 24px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 48px;
	//position: sticky;
	top: 0;
	z-index: 15;
	background-color: white;

	&__item {
		font-family: $font-mulish-regular;
		font-size: 14px;
		line-height: 1.7; 
		color: #3C4346;


		&__title:first-child {
			font-weight: bold;
		}

		&__text {
			font-family: $font-mulish-light;
			font-size: 12px;
			display: flex;

			&::after {
				content: url('../../assets/svg/Files.svg');
				display: block;
				margin-left: 8px;
				cursor: pointer;
			}
		}
	}

}