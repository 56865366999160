@import 'styles/variables';

.sendNotificationPage {
  font-family: $font-mulish-light;

  &__heading {
    font-family: $font-roboto-bold;
    font-size: 24px;
    letter-spacing: 0.96px;
    background: white;
    color: $color-black-dark;
    padding-bottom: 30px;
  }

  &__protocolWrapper {
    display: flex;
    padding-bottom: 40px;
    gap: 30px;
  }

  &__protocolHeading {
    font-family: $font-mulish-light;
    font-size: 18px;
    color: $color-grey-mid;
    padding-bottom: 10px;
  }

  &__protocolItem {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__outputData {
    font-family: $font-mulish-light;
    width: 500px;
    height: 315px;
    background: $color-grey-light;
    border-radius: 10px;
    font-size: 18px;
    padding: 12px 16px;
    overflow-y: auto;
  }

  &__textarea {
    width: 500px;
    height: 315px;
    font-size: 18px;
    color: $color-black-dark;
    border-radius: 10px;
    border: 1px solid $color-grey-light;
    background: #F2F6F8;
    padding: 12px 16px;
    line-height: 24px;
    font-family: $font-mulish-light;
    margin-bottom: 20px;

    &:focus {
      background: #FAFDFF;
      box-shadow: 2px 2px 6px 0 rgba(95, 96, 108, 0.15);
    }
  }

  &__list {
    padding-left: 1em;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 3px;
  }



}