.wrapper {
	//max-width: 1440px;
	min-height: 100vh;
	margin: 0 auto;
	padding: 24px 20px;
	display: flex;

}


.layout {
	width: 100%;
	height: 100%;

	&__body {
		max-width: 1160px;
		margin-left: calc(50vw - 580px - 200px);
		height: 100%;
		flex: 1 auto;
		display: flex;
		flex-direction: column;

		@media (max-width: 1720px) {
			margin-left: 80px;
		}
	}
}

.outlet__container {
	min-width: 100%;
	min-height: 100%;
	overflow-x: visible;
}
