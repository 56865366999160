//.wrapper {
//	max-width: 1440px;
//	height: 100vh;
//	margin: 0 auto;
//	padding: 24px 20px;
//}

.app {
  width: 100%;
  min-height: 100vh;
}
