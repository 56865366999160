// fonts
@font-face {
	font-family: 'mulish-light';
	src: url('./fonts/Mulish-Light.woff2') format('woff2'),
			 url('./fonts/Mulish-Light.woff2') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'mulish-regular';
	src: url('./fonts/Mulish-Regular.woff2') format('woff2'),
			 url('./fonts/Mulish-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'mulish-bold';
	src: url('./fonts/Mulish-Bold.woff') format('woff2'),
			 url('./fonts/Mulish-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'roboto-bold';
	src: url('./fonts/Roboto-Bold.woff2') format('woff2'),
			 url('./fonts/Roboto-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}
