@import 'styles/variables';

.deleteButton{
  width: 50px;
  height: 50px;
  visibility: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: $color-red solid 1px;
  border-radius: 16px;
  background: none;

  & > svg {
    fill: $color-red;
    width: 24px;
    height: 24px;
  }

  &:hover {
    background: $color-red;
    > svg {
      fill: white;
    }
  }

  &:active {
    background: $color-dark-red;
    > svg {
      fill: white;
    }
  }
}