@import 'styles/variables';

.loginPage {

  width: 100vw;
  height: 100vh;
  background: url("/assets/img/loginBackground.jpg") no-repeat center;
  background-size: cover;
  //надеюсь от этого избавиться когда переделаю Layout
  //position: absolute;
  //top: 0;
  left: 0;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;


  &__image {
    width: 224px;
    height: 40px;
    overflow: visible;
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-family: $font-roboto-bold;
    font-size: 24px;
    letter-spacing: 0.96px;
    color: white;
    position: sticky;
    top: 0;
    z-index: 11;
  }


}