/* <-- variables --> */

// fonts
$font-mulish-light: mulish-light, sans-serif;
$font-mulish-regular: mulish-regular, sans-serif;
$font-mulish-bold: mulish-bold, sans-serif;
$font-roboto-bold: roboto-bold, sans-serif;

// main colors
$color-grey-light: #DADBDB;
$color-grey-mid: #A9A9A9;
$color-grey-dark: #555F70;
$color-grey-blue: #E1E7F2;
$color-blue-btn: #0093F0;
$color-blue-highlight-dark:  rgba(0, 147, 240, 0.26);
$color-blue-highlight-light:  rgba(0, 147, 240, 0.13);
$color-dark-blue: #227ECB;
$color-red: #C94D3C;
$color-dark-red: #a23e30;

// text colors
$color-white: #FAFDFF;
$color-text-dark-grey: #3C4346;
$color-black-dark: #181818;

//layout colors
$color-hover: $color-white;
$mainColor: $color-blue-btn;
$mainColorDarker: $color-dark-blue;

:export {
  mainColor: $color-blue-btn;
  fontMulishLight: $font-mulish-light;

}
