@import 'reset';
@import 'fonts';
@import 'variables';
@import "animations";


body {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: $color-grey-light;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
  }
}

