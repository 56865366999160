@import 'styles/variables';

.textAreaWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: baseline;
  padding-bottom: 20px;
}

.appLabel {
  color: black;
  font-family: $font-mulish-light;
  font-size: 14px;
  line-height: 18px;
}

.appTextArea {
  width: 500px;
  height: 40px;
  font-family: $font-mulish-light;
  font-size: 18px;
  color: $color-black-dark;
  border-radius: 10px;
  border: 1px solid $color-grey-light;
  background: #F2F6F8;
  padding: 12px 16px;
  line-height: 24px;

  &:focus {
    background: #FAFDFF;
    box-shadow: 2px 2px 6px 0px rgba(95, 96, 108, 0.15);
  }

}