.appModal {
  &__wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color:rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    display: none;
  }

  &__closed {
    display: flex;
  }
}