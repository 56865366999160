@import 'styles/variables';

.appButton {

    display: inline-block;
    font-family: $font-mulish-bold;
    font-size: 14px;
    line-height: 24px;
    border-radius: 16px;

    &__standart {
        padding: 11px 38px;
        color: $color-white;
        background: $color-blue-btn;
        border: 1px solid $color-blue-btn;
        //box-shadow: 4px 4px 6px 0 rgba(60, 61, 64, 0.25);
        &:hover {
            background: $mainColorDarker;
            border: 1px solid $mainColorDarker;
        }

        &:active {
            background: $mainColorDarker;
            border: 1px solid $mainColorDarker;
            color: black;
            box-shadow: none;
        }

        &:disabled {
            opacity: 50%;
            box-shadow: 4px 4px 6px 0 rgba(60, 61, 64, 0.25);
            color: $color-white;
            &:hover {
                background: $color-blue-btn;
            }
        }
    }

    &__warning {
        padding: 11px 38px;
        color: $color-white;
        background: $color-red;
        //box-shadow: 4px 4px 6px 0 rgba(60, 61, 64, 0.25);

        &:hover {
            background: $color-dark-red;
        }

        &:active {
            color: black;
            box-shadow: none;
            //transform: translate(4px, 4px);
        }
    }

    &__white {
        padding: 11px 0;
        color: black;
        background: white;

        &:hover {
            color: $color-blue-btn;
        }

        &:active {
            color: $color-dark-blue;
        }
    }

    &__transparent {
        padding: 11px 38px;
        color: $color-blue-btn;
        border: 1px $color-blue-btn solid;
        background: white;

        &:hover {
            border: 1px solid $color-dark-blue;
            color: $color-dark-blue;
        }

        &:active {
            border: $color-dark-blue 1px solid;
            background: $color-dark-blue;
            color: white;
        }

        &:disabled {
            opacity: 50%;

            &:hover {
                background: white;
                border: 1px $color-blue-btn solid;
                color: $color-blue-btn;
            }

        }

    }
}
