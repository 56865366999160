@import 'styles/variables';

.registrationForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__inputWrapper {
    display: flex;
    gap: 30px;
  }

  &__input {
    width: 340px;
    height: 44px;
    font-family: $font-mulish-light;
    font-size: 16px;
    color: $color-black-dark;
    border-radius: 10px;
    border: 1px solid rgba(111, 112, 115, 1);
    padding: 12px 16px;
    line-height: 20px;

    &:focus {
      background: #FAFDFF;
      box-shadow: 2px 2px 6px 0 rgba(95, 96, 108, 0.15);
    }
  }


  &__label {
    color: black;
    font-family: $font-mulish-light;
    font-size: 14px;
    line-height: 18px;
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 30px;
  }

  &__registrationButton {
    background: $color-blue-btn;
    width: 100%;
    font-family: $font-mulish-bold;
    font-size: 14px;
    line-height: 24px;
    color: white;
    align-items: center;
    padding: 12px 0;
    display: block;
    text-align: center;
    border-radius: 16px;
    margin-bottom: 32px;

    &:not(:disabled):hover {
      background: $mainColorDarker;
    }

    &:not(:disabled):active {
      background: $mainColorDarker;
      color: black;
    }

    &:disabled {
      opacity: 26%;
    }
  }

  &__loginButton {
    background: none;
    color: black;
    display: inline-block;
    font-family: $font-mulish-bold;
    font-size: 14px;
    border-bottom: 1px solid black;
    margin-bottom: 32px;

    &:hover {
      color: $mainColorDarker;
      border-bottom: 1px solid $mainColorDarker;
    }

    &:active {
      color: white;
      border-bottom: 1px solid white;
    }
  }

  &__registeredQuestion {
    font-family: $font-mulish-light;
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 6px;
  }

  &__comment {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    font-family: $font-mulish-light;;
  }

}