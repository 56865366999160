@import 'styles/variables';

.userPic {
  width: 64px;
  height: 64px;
  color: white;
  background: $color-blue-btn;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-family: $font-mulish-bold;
}