
* {
	padding: 0;
	margin: 0;
	border: 0;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;

	&:before, 
	&:after {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	&::-webkit-scrollbar {
		-webkit-appearance: none;
	}

	&::-webkit-scrollbar:vertical {
		width: 11px;
	}

	&::-webkit-scrollbar:horizontal {
		height: 11px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 8px;
		border: 2px solid white; /* should match background, can't be transparent */
		background-color: #DADBDB;
	}

	&::-webkit-scrollbar-track {
		background-color: #fff;
		border-radius: 8px;
	}

}

:focus, :active {
	outline: none;
}

html, body {
	height: 100%;
	width: 100%;
	font-size: 100%;
	line-height: 1;
	font-size: 14px;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

a {
	text-decoration: none;

	&:focus,
	&:active {
		outline: none;
	}

	&:hover,
	&:visited {
		text-decoration: none;
	}
}

nav, 
footer, 
header, 
aside {
	display: block;
}

input {
	font-family: inherit;

	&::-ms-clear {
		display: none;
	}
}

textarea {
	font-family: inherit;
}

button {
	font-family: inherit;
	cursor: pointer;

	&::-moz-focus-inner {
		padding: 0;
		border: 0;
	}
} 

ul {
	li {
		list-style: none;
	}
}

h1,h2,h3,h4,h5,h6 {
	font-size: inherit;
	font-weight: 400;
}