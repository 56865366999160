@import 'styles/variables';

.sidebar {
	padding: 40px 0 40px;
	flex: 0 0 200px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: $font-mulish-light;
	font-size: 12px;
	border-radius: 20px;
	background: linear-gradient(180deg, #778395 0%, #555F70 100%);
	box-shadow: 4px 0 10px 0 rgba(56, 55, 58, 0.3);
	color: $color-white;
	overflow-y: hidden;
	width: 200px;
	position: sticky;
	top: 24px;
	height: calc(100vh - 48px);

	&__logoImg {
		width: 80px;
		margin-top: 12px;
		margin-bottom: 40px;
	}

	&__user {
		padding: 8px 32px 24px 24px;
		align-self: flex-start;
		display: flex;
		align-items: center;
		font-family: $font-mulish-light;
		font-size: 14px;
		color: $color-white;

		&::before {
			content: url('../../assets/svg/Ellipse.svg');
			display: block;
			width: 28px;
			height: 28px;
			margin-right: 16px;
		}
	}

	&__navbar {
		width: 100%;
		padding-left: 28px;
		flex: 1 1 auto;
	}

	&__footer {

		:hover {
			color: $color-hover;
			cursor: pointer;
		}

		&_text {
			font-family: $font-mulish-regular;
			font-size: 14px;
			color: $color-white;
		}
	}
}